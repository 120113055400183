export interface IConfig {
  NotificationRulesResource: string;
  aadUrl: string;
  currentEnvironment: string;
  tenant: string;
  serviceUrl: string;
  resource: string;
  websiteRedirectUri: string;
  graphUrl: string;
  NotificationRulesClientId: string;
  frequencyConst: string;
}
export let config: IConfig;

export async function loadConfig() {
  let configFile = await fetch(process.env.PUBLIC_URL + "assets/appConfig.json");
  let configFileValues = await configFile.json();
  let currentConfigVals = {} as IConfig;
  
  currentConfigVals.currentEnvironment = configFileValues.currentEnvironment;
  let currentEnvironmentConfig =
    configFileValues.config[currentConfigVals.currentEnvironment];
  currentConfigVals.tenant = currentEnvironmentConfig.AADTenant;
  currentConfigVals.serviceUrl = currentEnvironmentConfig.NotificationSfUrl;
  currentConfigVals.aadUrl = currentEnvironmentConfig.AADUrl;
  currentConfigVals.websiteRedirectUri =
    currentEnvironmentConfig.WebsiteRedirectUri;
  currentConfigVals.resource = currentEnvironmentConfig.NotificationRulesResource;
  currentConfigVals.graphUrl = currentEnvironmentConfig.GraphApiUrl;
  currentConfigVals.NotificationRulesClientId =
    currentEnvironmentConfig.NotificationRulesClientId;
  currentConfigVals.NotificationRulesResource =
    currentEnvironmentConfig.NotificationRulesResource;
  currentConfigVals.frequencyConst = configFileValues.frequencyConst;
  config = currentConfigVals
  return config;
}