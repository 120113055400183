export class Constants {
  public static readonly EMPTY_CONST = "-";
  public static readonly FREQUENCY_CONST = "On Availability";
  public static readonly ORDER_NOTIFICATIONS = [["MEO","InternalEmail"], ["UCI"], ["Teams"],["Push"]];
  public static readonly GROUP_NAMES = [
    "Email Notifications",
    "MSX CRM RA Card",
    "Microsoft Teams",
    "Push",
  ];
  public static readonly BASE_URL = "api/v1/noru/";
  public static readonly THUMBNAIL_URL =
    "https://graph.microsoft.com/v1.0/me/photo/$value";
  public static readonly USER_INFO_URL = "https://graph.microsoft.com/v1.0/me/";
  public static readonly ADMIN_UI = "AdminUI";
  public static readonly NORU = "NoRu";
  public static readonly NIL_GUID = "00000000-0000-0000-0000-000000000000";

  public static readonly CONFIRM_SUBSCRIPTION = "You will be subscribed to following notifications with the same Digest. Do you want to continue?";
  public static readonly CONFIRM_UNSUBSCRIPTION = "You will be unsubscribed from following notifications with the same Digest. Do you want to continue?";

  public static readonly ERROR_TITLE = "Error Occurred";
  public static readonly ERROR_DESC = "Error Occurred while changing status please try after sometime";
  public static readonly SUCCESSFUL_SUBSRIPTION = "Subscription Successful";
  public static readonly SUCCESSFUL_SUBSRIPTION_DESC = "Request for subscription is successful, You will now receive notification of this workflow";
  public static readonly SUCCESSFUL_UNSUBSCRIPTION = "Un-Subscription Successful";
  public static readonly SUCCESSFUL_UNSUBSCRIPTION_DESC = "Request for unsubscribe workflow is successful, You will now not receive notification of this workflow";

  // HTML TAGS
  public static readonly HTML_UL_TAG = "<ul>";
  public static readonly HTML_UL_CLOSING_TAG = "</ul>";
  public static readonly HTML_LI_TAG = "<li>";
  public static readonly HTML_LI_CLOSING_TAG = "</li>";
}
