import { IColumn } from "office-ui-fabric-react/lib/DetailsList";
import { Constants } from "../helpers/Constants";
import { IUserWorkflowModel } from "../helpers/UserWorkflow.model";

export enum SortOrder {
  ascending = 0,
  descending = 1,
}

export function sortArray<T>(items: T[], keys: string[], order: SortOrder[]) {
  const defaultKey = keys[0] as keyof T;
  const secondaryKey = keys[1] as keyof T;

  return items.slice(0).sort(function (a: T, b: T) {
    if (!a[defaultKey])
      return order[0] ? 1 : -1
    if (!b[defaultKey])
      return order[0] ? -1 : 1
    let comparison = order[0]
      ? a[defaultKey] < b[defaultKey]
      : a[defaultKey] > b[defaultKey];
    if (!comparison && a[defaultKey] === b[defaultKey] && secondaryKey) {
      comparison = order[1]
        ? a[secondaryKey] < b[secondaryKey]
        : a[secondaryKey] > b[secondaryKey];
    }
    let out = comparison ? 1 : -1;
    return out;
  });
}

// export function _copyAndSort<T>(
//   items: T[],
//   columnKey: string,
//   isSortedDescending?: boolean
// ): T[] {
//   const key = columnKey as keyof T;
//   return items
//     .slice(0)
//     .sort((a: T, b: T) =>
//       (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
//     );
// }

export function _filterHelper<T>(
  items: T[],
  text: string | undefined,
  columnKeys: string[],
  casing: boolean
) {
  text = casing && text ? text : text!.toLowerCase();
  let rows = text
    ? items.filter((i) => {
        let result: boolean = false;
        columnKeys.forEach((columnKey) => {
          const key = columnKey as keyof T;

          let cellValue = i[key] ? JSON.stringify(i[key]) : "";
          result =
            result || cellValue.toString().toLowerCase().indexOf(text!) > -1;
        });
        return result;
      })
    : items;
  return rows;
}

export function getColumnKeys(columns: IColumn[]) {
  let columnKeys = columns.map((column) => column.fieldName!);
  columnKeys = columnKeys ? columnKeys : [];
  return columnKeys;
}

export function formatDate(date: string) {
  //date : Jul 28 2020 5:00AM
  let dateParts = date.split(' ');
  if (dateParts && dateParts.length > 3) {
    dateParts.pop()
  }
  return dateParts.length >= 1 ? dateParts.join(' ').trim() : Constants.EMPTY_CONST;
}

export function getDialogContent(
  success: boolean,
  isSubscribed?: number
): { title: string; description: string } {
  let title = Constants.ERROR_TITLE;
  let description = Constants.ERROR_DESC;
  if (success) {
    if (isSubscribed) {
      title = Constants.SUCCESSFUL_SUBSRIPTION;
      description = Constants.SUCCESSFUL_SUBSRIPTION_DESC;
    } else {
      title = Constants.SUCCESSFUL_UNSUBSCRIPTION;
      description = Constants.SUCCESSFUL_UNSUBSCRIPTION_DESC;
    }
  }
  return { title: title, description: description };
}

export function getNotificationName(workflow: IUserWorkflowModel): string {
  if (workflow && workflow.notificationName)
    return workflow.notificationName;
  if (workflow.schemaName && workflow.schemaName.toLowerCase() === Constants.ADMIN_UI.toLowerCase())
    return workflow.schemaName;
  return Constants.EMPTY_CONST;
}

export function isValidGuid(dpid: string) {
  if (!dpid || dpid === Constants.NIL_GUID )
    return false;
  return true;
}

export function isDigestSame(workflow: IUserWorkflowModel, workflow1: IUserWorkflowModel): boolean {
  if (!workflow.dpid || !workflow1.dpid) {
    return false;
  }
  if (workflow.deliverySurfaceTypeId !== workflow1.deliverySurfaceTypeId) {
    return false;
  }
  if (!isValidGuid(workflow.dpid) || !isValidGuid(workflow1.dpid)) {
    return false;
  }

  if (workflow.dpid === workflow1.dpid) {
    return true;
  }
  return false;
}

export function getConfirmationMessage(IsSubscribed: boolean): string {
  if (IsSubscribed) {
    return Constants.CONFIRM_SUBSCRIPTION;
  }
  return Constants.CONFIRM_UNSUBSCRIPTION;
}

export function getHTMLListFromArray(listArray:string[]): string {
    let list:string = Constants.HTML_UL_TAG;
    listArray.forEach(item => list = list + Constants.HTML_LI_TAG + item + Constants.HTML_LI_CLOSING_TAG);
    return list + Constants.HTML_UL_CLOSING_TAG;
}