import ReactDOM from "react-dom";
import { loadConfig } from "./services/ConfigService";

const rootElement = document.getElementById("root");


loadConfig()
  .then((config) => {
    require('./services/AuthService').handleAuthRedirect()
      .then(() => {
        // Load our application into memory, now that we are authenticated.
        require('./indexApp.tsx');
      })
      .catch((error: any) => {
      });
  })
  .catch(error => {
    rootElement!.innerHTML = "<h1>Unable to load the app!</h1><p>Could not load configuration. Please check your internet connection.</p>";
  });