import React, { Component } from "react";
import "./UserWorkflows.scss";
import HeaderPane from "../../components/HeaderPane/HeaderPane";
import WorkflowList from "../../components/WorkflowList/WorkflowList";
import ErrorDialog from "../../components/ErrorDialog/ErrorDialog";
import DataService from "../../services/DataService";
import {
  groupItemsByChannel,
  createGroups,
} from "../../helpers/GroupingHelper";
import { IUserWorkflowModel } from "../../helpers/UserWorkflow.model";
import { Constants } from "../../helpers/Constants";

export default class UserWorkflows extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      text: "",
      items: [],
      groups: undefined, // * it is undefined because of the shimmering for groups does not work work
      error: false,
    };
  }

  render() {
    let { text, items, groups, error } = this.state;
    return (
      <div className="UserWorkflows">
        <HeaderPane setText={this._changeText} />
        <WorkflowList text={text} items={items} groups={groups} />
        {error && (
          <ErrorDialog
            title="Error Occurred"
            subText="Error occurred while loading the data please try again"
            hideDialog={this._hideDialog}
          />
        )}
      </div>
    );
  }

  async componentDidMount() {
    //let items = getData();
    try {
      let apiResponse = await DataService.getUserWorkflows();
      let items: IUserWorkflowModel[] = apiResponse as IUserWorkflowModel[];
      let itemsMap = groupItemsByChannel(items);
      let object = createGroups(
        itemsMap,
        Constants.ORDER_NOTIFICATIONS,
        Constants.GROUP_NAMES
      );

      this.setState({ items: object.items, groups: object.groups });
    } catch {
      this.setState({ groups: [], error: true });
    }
  }

  private _changeText = (searchText: string) => {
    this.setState({ text: searchText });
  };

  private _hideDialog = () => {
    this.setState({ error: !this.state.error });
  };
}
