import { msalConfig } from "../authConfig";
import { UserAgentApplication, AuthenticationParameters, Account} from "msal";
import { IConfig } from "./ConfigService";

export let account: Account | null;
export let config: IConfig;
function authCallback(error: any, response: any) {
    if(response) {
        account = response.Account;
    }
}

export const msalInstance = new UserAgentApplication(msalConfig);
msalInstance.handleRedirectCallback(authCallback);

export async function getTokenForApi(scope: string[]): Promise<string | null> {
    const authParam: AuthenticationParameters = {
        scopes: scope
    }
    try {
        account = msalInstance.getAccount();
        if(account) {
            const response = await msalInstance.acquireTokenSilent(authParam);
            return response.accessToken;
        }
    } catch (error) {
        msalInstance.acquireTokenRedirect(authParam);
    }
    return "";
}

export function handleAuthRedirect(): Promise<any> {
    try {
        const authParam: AuthenticationParameters = {
            scopes: [msalConfig.auth.clientId]
        }
        if (msalInstance.getAccount() && !msalInstance.isCallback(window.location.hash)) {
            account = msalInstance.getAccount();
        } else {
            msalInstance.loginRedirect(authParam);
        }
        return msalInstance.acquireTokenSilent(authParam)
    } catch (error) {
        throw error;
    }
}

export async function logOut(): Promise<void> {
    try {
        msalInstance.logout();
    } catch (error) {
        throw error;
    }
}