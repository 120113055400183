import React, { Component } from "react";
import {
  DetailsListLayoutMode,
  SelectionMode,
  IColumn,
  IGroup,
} from "office-ui-fabric-react/lib/DetailsList";
import { Toggle } from "office-ui-fabric-react/lib/Toggle";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import "./WorkflowList.scss";
import { IUserWorkflowModel } from "../../helpers/UserWorkflow.model";
import { ISubscriptions } from "../../helpers/Subscription.model";
import { ISchema } from "../../helpers/Schema.model";
import {
  getColumnKeys,
  _filterHelper,
  formatDate,
  getDialogContent,
  sortArray,
  SortOrder,
  getNotificationName,
  isDigestSame,
  getConfirmationMessage,
  getHTMLListFromArray
} from "../../helpers/WorkflowListHelper";
import { Constants } from "../../helpers/Constants";
import ErrorDialog from "../ErrorDialog/ErrorDialog";
import DataService from "../../services/DataService";
import {config, IConfig } from "../../services/ConfigService";
import { SubscriptionType } from "../../helpers/SubscriptionTypeEnum";
import ConfirmationDialog  from "../ConfirmationDialog/ConfirmationDialog";

interface IState {
  items: IUserWorkflowModel[];
  columns: IColumn[];
  groups: IGroup[];
  isDataLoaded: boolean;
  dialogBox: boolean;
  confirmationDialog: boolean;
}

interface IProps {
  text: string;
  items: IUserWorkflowModel[];
  groups: IGroup[];
}

export default class WorkflowList extends Component<IProps, IState> {
  private _config: IConfig;
  private _allItems: IUserWorkflowModel[];
  private _allGroups: IGroup[];
  private _columnKeys: string[];
  private _dialogTitle: string;
  private _dialogDescription: string;
  private _confirmationDialogProps: IConfirmationDialogProps;

  constructor(props: IProps) {
    super(props);
    let { items, groups } = props;
    this._allItems = items;
    this._allGroups = groups;
    this._dialogTitle = "";
    this._dialogDescription = "";
    this._config = {} as IConfig;
    this._confirmationDialogProps = {} as IConfirmationDialogProps;

    const columns: IColumn[] = this._getColumns(this._allItems.length);
    this.state = {
      items: this._allItems,
      columns: columns,
      groups: groups,
      isDataLoaded: this._allItems.length ? true : false,
      dialogBox: false,
      confirmationDialog: false
    };

    this._columnKeys = getColumnKeys(this.state.columns);
    this.updateSubscription = this.updateSubscription.bind(this);
  }

  render() {
    let { items, columns, groups, isDataLoaded } = this.state;
    return (
      <div className="WorkflowList">
        <ShimmeredDetailsList
          items={items}
          compact={true}
          columns={columns}
          onRenderItemColumn={this._onRenderColumn}
          selectionMode={SelectionMode.none}
          groups={groups}
          enableShimmer={!isDataLoaded}
          //getKey={this._getKey}
          setKey="none"
          layoutMode={DetailsListLayoutMode.fixedColumns}
          isHeaderVisible={true}
          ariaLabelForSelectionColumn="Toggle selection"
          //ariaLabelForSelectAllCheckbox="Toggle selection for all items"
          //checkButtonAriaLabel="Row checkbox"
          className="DetailGrid"
        />
        {this.state.dialogBox && (
          <ErrorDialog
            title={this._dialogTitle}
            subText={this._dialogDescription}
            hideDialog={this._hideDialog}
          />
        )}
        {this.state.confirmationDialog && (
          <ConfirmationDialog
            title={this._confirmationDialogProps.DialogTitle}
            subText={this._confirmationDialogProps.DialogDescription}
            yesCallbackFunc={this._confirmationDialogProps.YesFuncCallback}
            hideDialog={this._hideConfirmationDialog}
            callbackFuncArgs={this._confirmationDialogProps.CallbackArgs}
            extraData={this._confirmationDialogProps.ExtraData}
          />
        )}
      </div>
    );
  }
  async componentDidMount() {
    this._config = config;
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.text !== this.props.text) {
      this._onChangeText(this.props.text);
    }

    if (prevProps.groups !== this.props.groups) {
      this._allGroups = this.props.groups;
      this._allItems = this.props.items;
      this.setState({
        groups: this.props.groups,
        items: this.props.items,
        isDataLoaded: true,
        columns: this._getColumns(this.props.items.length),
      });
    }

    if (prevProps.items !== this.props.items) {
      this.setState({
        items: this.props.items,
        columns: this._getColumns(this.props.items.length),
      });
    }
  }

  getStateValue(key: string) {
    let stateKey = key as keyof IState;
    return JSON.parse(JSON.stringify(this.state[stateKey]));
  }

  private _getColumns(count: number) {
    let columns: IColumn[] = [
      {
        key: "notificationName",
        name: "Manage Notifications (" + count + ")",
        className: "ColumnNameItem",
        ariaLabel:
          "Column operations for Notification Name, Press to sort on Notification Name",
        fieldName: "notificationName",
        data: "string",
        minWidth: 200,
        maxWidth: 400,
        headerClassName: count ? "workflowName-active" : "workflowName",
        isResizable: true,
        //isPadded: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: "frequency",
        name: "Frequency",
        ariaLabel:
          "Column operations for Frequency, Press to sort on Frequency",
        fieldName: "frequency",
        data: "string",
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        //isPadded: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: "lastReceivedDate",
        name: "Last Received Date",
        ariaLabel:
          "Column operations for Last Received Date, Press to sort on Date",
        fieldName: "lastReceivedDate",
        data: "string",
        minWidth: 100,
        maxWidth: 150,
        isPadded: true,
        isResizable: true,
        headerClassName: "date-header",
        className: "date-column",
        onColumnClick: this._onColumnClick,
      },
      {
        key: "entityName",
        name: "Entity",
        ariaLabel: "Column operations for Entity, Press to sort on Entity",
        fieldName: "entityName",
        data: "string",
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        //isPadded: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: "isSubscribed",
        name: "On/Off",
        ariaLabel: "Column operations for Subscription",
        fieldName: "isSubscribed",
        data: "number",
        minWidth: 50,
        maxWidth: 100,
        isResizable: true,
        //isPadded: true,
        onColumnClick: this._onColumnClick,
      },
    ];
    return columns;
  }

  // * This function for sorting the list for functions not implemented for date type

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    let { columns, items, groups } = this.state;
    items.map((item) => {
      item.notificationName = item.notificationName? item.notificationName : getNotificationName(item);
      return item;
    });
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    let newItems: IUserWorkflowModel[] = [];
    let order = currColumn.isSortedDescending
      ? SortOrder.descending
      : SortOrder.ascending;

    groups.forEach((group) => {
      const { startIndex, count } = group;
      const tempItems = sortArray(
        items.slice(startIndex, startIndex + count),
        [currColumn.fieldName!],
        [order]
      );
      newItems.push(...tempItems);
    });

    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _onChangeText = (
    text: string | undefined,
    ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    let items: IUserWorkflowModel[] = [];
    let groups: IGroup[] = JSON.parse(JSON.stringify(this._allGroups));
    let allItems: IUserWorkflowModel[] = JSON.parse(
      JSON.stringify(this._allItems)
    );
    let prevIndex = 0;

    allItems.map((item) => {
      item.lastReceivedDate = item.lastReceivedDate
        ? formatDate(item.lastReceivedDate)
        : Constants.EMPTY_CONST;
      item.frequency = item.frequency
        ? item.frequency
        : config.frequencyConst;
      item.notificationName = item.notificationName? item.notificationName : getNotificationName(item);
      return item;
    });

    groups.map((group) => {
      const { startIndex, count } = group;
      var filterItems = _filterHelper(
        allItems.slice(startIndex, startIndex + count),
        text,
        this._columnKeys,
        false
      );
      items.push(...filterItems);
      group.startIndex = prevIndex;
      group.count = filterItems.length;
      prevIndex = prevIndex + filterItems.length;
      return group;
    });
    this.setState({
      items: items,
      groups: groups,
      columns: this._getColumns(items.length),
    });
  };

  _onRenderColumn = (
    item: IUserWorkflowModel,
    index: number | undefined,
    column: IColumn | undefined
  ) => {
    column = column!;
    const fieldContent = item[
      column.fieldName as keyof IUserWorkflowModel
    ] as string;

    switch (column.key) {
      case "notificationName":
        let description = item.description;
        let notificationName = fieldContent;

        if (!notificationName)
          notificationName = getNotificationName(item);

        if (notificationName === Constants.EMPTY_CONST || !notificationName)
          return <span className="gridContent">{notificationName}</span>

        return <i className="icon icon-info customInfoIcon">
          <span className="gridContent">{notificationName}</span>
          <span className="tooltip">{description}</span>
        </i>;
      
      case "lastReceivedDate":
        let date: string = Constants.EMPTY_CONST;
        if (fieldContent && fieldContent !== Constants.EMPTY_CONST)
          date = formatDate(fieldContent);
        return <span className="gridContent">{date}</span>;
      
      case "isSubscribed":
        let subscribed = false;
        if (Number(fieldContent) === 1) subscribed = true;
        return (
          <Toggle
            ariaLabel="Subscription Status"
            checked={subscribed}
            onChange={(
              event: React.MouseEvent<HTMLElement>,
              checked?: boolean
            ) => this._onSubscriptionChanged(event, checked!, index!)}
          />
        );

      case "frequency":
        let frequencyString = config.frequencyConst;
        if (fieldContent) frequencyString = fieldContent;
        return <span className="gridContent">{frequencyString}</span>;

      default:
        const value = fieldContent || Constants.EMPTY_CONST;
        return <span className="gridContent">{value}</span>;
    }
  };

  private _onSubscriptionChanged(
    event: React.MouseEvent<HTMLElement>,
    checked: boolean,
    index: number
  ) {
    let status: 1 | 0 = checked ? 1 : 0;

    let IsSubscribed: true | false = checked ? true : false;
    let items = [...this.state.items];

    try {
      let subscriptionData: ISubscriptions[] = [{ SchemaName: items[index].schemaName, MessageType: items[index].deliverySurfaceTypeId, IsSubscribed: IsSubscribed }];
      let wfList: string[] = [];
      let notificationName = getNotificationName(items[index])
      wfList.push(notificationName)

      if (items[index].subscriptionType?.toLowerCase() === SubscriptionType.AutoSubscribe.toLowerCase()) {
        this._allItems.forEach((item) => {
          if (item.notificationName !== items[index].notificationName && item.isSubscribed !== status && isDigestSame(item, items[index])) {
            subscriptionData.push({
              SchemaName: item.schemaName, MessageType: item.deliverySurfaceTypeId, IsSubscribed: IsSubscribed
            });
            wfList.push(item.notificationName || getNotificationName(item))
          }
        });
      }

      if(wfList.length > 1) {
        this._confirmationDialogProps.DialogTitle = "Are you sure?"
        this._confirmationDialogProps.DialogDescription = getConfirmationMessage(IsSubscribed)
        this._confirmationDialogProps.ExtraData = getHTMLListFromArray(wfList)
        this._confirmationDialogProps.CallbackArgs = {
          status: status,
          subscriptionData: subscriptionData
        }
        this._confirmationDialogProps.YesFuncCallback = this.updateSubscription;
        this.setState({
          confirmationDialog: true
        })
      } else {
        this.updateSubscription({
          status: status,
          subscriptionData: subscriptionData
        })
      }
    }catch {
      return this.showErrorDialog();
    }
  }

  private _hideDialog = () => {
    this.setState({ dialogBox: !this.state.dialogBox });
  };

  private _hideConfirmationDialog = () => {
    this.setState({ confirmationDialog: !this.state.confirmationDialog });
  };

  async updateSubscription(...args: any[]) {
    let param = args[0];
    if (!param || !param.subscriptionData || !typeof param.status) {
      return this.showErrorDialog();
    }

    let status = param.status;
    let subscriptionData: ISubscriptions[] = param.subscriptionData;
    let schemaData = {} as ISchema;
    schemaData.Subscriptions = subscriptionData;

    let response = await DataService.updateSchemaSubscription(schemaData);
    response = response as ISubscribeAPIResponse;
    if (!response || !response.isSuccessful) {
      return this.showErrorDialog();
    }

    let updatedItems = [] as IUserWorkflowModel[];
    let subscriptionDetail: string[] = [];
    subscriptionData.forEach(item => subscriptionDetail.push(item.SchemaName+item.MessageType))

    this._allItems.forEach((item) => {
      if (subscriptionDetail.includes(item.schemaName+item.deliverySurfaceTypeId)) {
        item.isSubscribed = status
      }
      updatedItems.push(item);
    })

    let modalObject = getDialogContent(true, status);
    this._dialogTitle = modalObject.title;
    this._dialogDescription = modalObject.description;

    this.setState({
      items: updatedItems,
      columns: this._getColumns(updatedItems.length),
      dialogBox: true,
    });
  }

  private showErrorDialog(): void {
    let modalObject = getDialogContent(false, -1);
    this._dialogTitle = modalObject.title;
    this._dialogDescription = modalObject.description;
    this.setState({
      dialogBox: true,
    });
    return;
  }
}

interface IConfirmationDialogProps {
  DialogTitle: string;
  DialogDescription: string;
  YesFuncCallback: () => void;
  NoFuncCallback: () => {};
  CallbackArgs: object;
  ExtraData: string;
}

interface ISubscribeAPIResponse {
  isSuccessful?: boolean;
  error?: string;
  message?: string;
}