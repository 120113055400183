import React, { Component } from "react";
import {
  SearchBox,
  ISearchBoxStyles,
} from "office-ui-fabric-react/lib/SearchBox";
import {
  IPersonaSharedProps,
  Persona,
  PersonaSize,
  PersonaInitialsColor,
  IPersonaStyles,
} from "office-ui-fabric-react/lib/Persona";
import UserService from "../../services/UserService";
import { initializeIcons } from "office-ui-fabric-react/lib/Icons";
import "./HeaderPane.scss";
initializeIcons();

export default class HeaderPane extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      userPersona: {},
    };
  }

  shouldComponentUpdate(nextProps: any, nextState: any) {
    if (nextProps !== this.props) return true;
    if (nextState !== this.state) {
      return true;
    }
    return false;
  }

  render() {
    const changeText = this.props.setText;
    const { userPersona } = this.state;
    return (
      <div className="HeaderPane">
        <label className="title">Manage Notifications</label>
        <SearchBox
          ariaLabel="Enter Text for searching"
          styles={this.searchBoxStyles}
          placeholder="Search"
          onChange={(event, newValue) => changeText(newValue)}
        />
        <Persona
          {...userPersona}
          size={PersonaSize.size40}
          hidePersonaDetails={!true}
          initialsColor={PersonaInitialsColor.pink}
          styles={this.personaStyles}
        />
      </div>
    );
  }

  async componentDidMount() {
    await UserService.getUserDetails();
    let user = UserService.user;
    let userPersona: IPersonaSharedProps = {
      imageUrl: user.thumbnailPhoto,
      imageInitials: user.initials,
      text: user.fullName,
      secondaryText: user.jobTitle,
    };
    this.setState({ userPersona: userPersona });
  }

  searchBoxStyles: Partial<ISearchBoxStyles> = {
    root: {
      width: "30%",
      opacity: 0.78,
      borderRadius: 2,
    },
  };

  personaStyles: Partial<IPersonaStyles> = {
    primaryText: {
      color: "#ffffff",
      lineHeight: 22,
      fontWeight: 500,
    },
    secondaryText: {
      fontSize: "x-small",
      color: "#ffffff",
      lineHeight: 22,
      fontWeight: 400,
    },
  };
}
