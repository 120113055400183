import { IGroup } from "office-ui-fabric-react/lib/DetailsList";
import { IUserWorkflowModel } from "./UserWorkflow.model";
import { SortOrder, sortArray } from "./WorkflowListHelper";

export function createGroups(
  itemsMap: Map<string, IUserWorkflowModel[]>,
  order: (string[])[],
  names: string[]
): { items: IUserWorkflowModel[]; groups: IGroup[] } {
  let items: IUserWorkflowModel[] = [];
  let groups: IGroup[] = [];
  let startIndex = 0;
  order.forEach((channels, index) => {
    let group: IGroup = {} as IGroup;
    let groupItems: IUserWorkflowModel[] = []
    channels.forEach((channel) => {
      let channelWorkflows = itemsMap.get(channel) ?? []
      groupItems.push(...channelWorkflows)
    })
    
    group.key = channels[0];
    groupItems = groupItems ? groupItems : [];
    group.name = names[index];
    group.startIndex = startIndex;
    group.count = groupItems.length;
    group.level = 0;
    if (group.count > 0) {
      items.push(...groupItems);
      groups.push(group);
    }
    startIndex = startIndex + group.count;
  });

  return { items: items, groups: groups };
}

// Returning Map for Grouping Reasons

export function groupItemsByChannel(
  items: IUserWorkflowModel[]
): Map<string, IUserWorkflowModel[]> {
  items = sortArray(
    items.slice(0),
    ["isSubscribed", "notificationName"],
    [SortOrder.descending, SortOrder.ascending]
  );

  let itemsMap = new Map<string, IUserWorkflowModel[]>();

  items.forEach((item) => {
    let i = itemsMap.get(item.deliverySurfaceType);
    i = i ? i : [];
    itemsMap.set(item.deliverySurfaceType, [...i, item]);
  });
  return itemsMap;
}
