import jwt_decode from "jwt-decode";
import DataService from "./DataService";
import { getTokenForApi } from "./AuthService";
import { protectedResources  } from "../authConfig";

export default class UserService {
  static user: IUser = {} as IUser;
  static token: string;

  static async getUserDetails() {
    let token = await getTokenForApi(protectedResources.graphApi.scopes)
    const userObject = await DataService.getUserInfo();
    this.token = token!;
    let decodedToken: IToken = jwt_decode(this.token);
    this.user.email = decodedToken.upn;
    this.user.firstName = decodedToken.given_name;
    this.user.lastName = decodedToken.family_name;
    this.user.fullName = this.user.firstName + " " + this.user.lastName;
    this.user.alias = decodedToken.upn.substring(
      0,
      this.user.email.lastIndexOf("@")
    );
    this.user.initials =
      this.user.firstName.substring(0, 1).toUpperCase() +
      this.user.lastName.substring(0, 1).toUpperCase();
    this.user.jobTitle = userObject.jobTitle;
    this.user.thumbnailPhoto = userObject.thumbnailPhoto;
  }
}

export interface IUser {
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  alias: string;
  initials: string;
  jobTitle: string;
  thumbnailPhoto: string;
}

interface IToken {
  family_name: string;
  given_name: string;
  iat: string;
  ipaddr: string;
  iss: string;
  name: string;
  nbf: string;
  nonce: string;
  oid: string;
  onprem_sid: string;
  rh: string;
  sub: string;
  tid: string;
  unique_name: string;
  upn: string;
}
