import React, { useEffect } from "react";
import "./ErrorDialog.scss";
import { Dialog, DialogType } from "office-ui-fabric-react/lib/Dialog";
import { useBoolean } from "@uifabric/react-hooks";
import {
  AnimationStyles,
  mergeStyles,
} from "office-ui-fabric-react/lib/Styling";

let dialogContentProps = {
  type: DialogType.close,
  title: "Error Occurred",
  closeButtonAriaLabel: "Close",
  subText: "Do you want to send this message without a subject?",
};

interface IProps {
  title: string;
  subText: string;
  hideDialog: () => void;
}

const modalPropsStyles = { main: { maxWidth: 450 } };
const animation = mergeStyles(AnimationStyles.scaleDownIn100, {});

export default function ErrorDialog(props: IProps) {
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      closeDialogBox();
    }, 3000);
    return () => clearTimeout(timer);
  });

  dialogContentProps.title = props.title;
  dialogContentProps.subText = props.subText;
  let hideDialogParent = props.hideDialog;
  const modalProps = React.useMemo(
    () => ({
      isBlocking: false,
      styles: modalPropsStyles,
      dragOptions: undefined,
    }),
    []
  );

  function closeDialogBox() {
    toggleHideDialog();
    hideDialogParent();
  }

  return (
    <div className="Error Dialog">
      <Dialog
        hidden={hideDialog}
        onDismiss={closeDialogBox}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
        className={animation}
      ></Dialog>
    </div>
  );
}
