import * as React from 'react';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { useBoolean } from '@uifabric/react-hooks';
import "./ConfirmationDialog.scss"

const dialogStyles = { main: { maxWidth: 450 } };

const dialogContentProps = {
  type: DialogType.normal,
  title: 'Missing Subject',
  closeButtonAriaLabel: 'Close',
  subText: 'Do you want to send this message without a subject?',
};

interface IProps {
  title: string;
  subText: string;
  hideDialog: () => void;
  yesCallbackFunc?: (...args: any[]) => void;
  callbackFuncArgs?: object | null;
  extraData?: string;
}

export default function ConfirmationDialog(props: IProps) {

  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(false);

  dialogContentProps.title = props.title;
  dialogContentProps.subText = props.subText;
  let extraData = props.extraData || '</>';
  let hideDialogParent = props.hideDialog;
  const modalProps = React.useMemo(
    () => ({
      isBlocking: false,
      styles: dialogStyles,
      dragOptions: undefined,
    }),
    []
  );

  function yesCallbackFunc() {
    toggleHideDialog();
    hideDialogParent();
    if (props.yesCallbackFunc)
      props.yesCallbackFunc(props.callbackFuncArgs);
  }

  function closeDialogBox() {
    toggleHideDialog();
    hideDialogParent();
  }

  return (
    <div className="Confirm Dialog">
      <Dialog
        hidden={hideDialog}
        onDismiss={closeDialogBox}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
      >
        <div className="ExtraData" dangerouslySetInnerHTML={{ __html: extraData }} />
        <DialogFooter>
          <PrimaryButton onClick={yesCallbackFunc}>Yes</PrimaryButton>
          <DefaultButton onClick={closeDialogBox}>No</DefaultButton>
        </DialogFooter>
      </Dialog>
    </div>
  );
}