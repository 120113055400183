import { CacheLocation } from "msal";
import { config } from "./services/ConfigService";

let cachelocation: CacheLocation = "localStorage"; 

export const msalConfig = {
    auth: {
        clientId: config.NotificationRulesClientId, 
    },
     cache: {
       cacheLocation : cachelocation
  }
};

export const protectedResources = {
    graphApi: {
        endpoint: config.graphUrl,
        scopes: ["user.read"],
    },
    noruApi : {
      endpoint: config.serviceUrl,
      scopes: [config.resource + "/user_impersonation"],
    }
}