import { config, IConfig } from "./ConfigService";
import { Constants } from "../helpers/Constants";
import { protectedResources } from "../authConfig"
import { getTokenForApi } from "./AuthService";

export default class DataService {
  static baseUrl = Constants.BASE_URL;
  static config: IConfig;
  
  public static async getUserWorkflows(data?: any) {
    const url =
      config.serviceUrl + this.baseUrl + "getSubscriptionEnabledNotifications";
    const accessToken = await getTokenForApi(protectedResources.noruApi.scopes);

    const requestOptions = {
      method: "POST",
      headers: {"Content-Type": "application/json", "Authorization": `Bearer ${accessToken}`},
      body: data ? JSON.stringify(data) : null,
    };
     const response = await fetch(url, requestOptions);
    return response? response.json() : {};
  }

  public static async updateSchemaSubscription(data: any) {
    const accessToken = await getTokenForApi(protectedResources.noruApi.scopes);
    const url = config.serviceUrl + this.baseUrl + "SetSchemaSubscriptions";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${accessToken}`},
      body: JSON.stringify(data),
    };
    
    const response = await fetch(url,requestOptions)
    return response? response.json() : {};
  }

  public static async getUserInfo() {
      const accessToken = await getTokenForApi(protectedResources.graphApi.scopes);

    let url = Constants.USER_INFO_URL;
    const photo = this.getUserThumbnailPhoto();
    const requestOptions = {
      method: "Get",
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${accessToken}`}
    };
    const response = await fetch(url, requestOptions);
    let userDetails = await response.json();
    let user = { jobTitle: "", thumbnailPhoto: "" };
    user.jobTitle = userDetails.jobTitle;
    user.thumbnailPhoto = await photo;

    return user;
  }

  public static async getUserThumbnailPhoto() {
    const accessToken = await getTokenForApi(protectedResources.graphApi.scopes);
    const url = Constants.THUMBNAIL_URL;
    const requestOptions = {
      method: "Get",
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${accessToken}`}
    };
    const response = await fetch(url, requestOptions);
    const buffer = await response?.arrayBuffer();
    var base64Flag = "data:image/jpeg;base64,";
    var imageStr = arrayBufferToBase64(buffer);
    const imageArray = base64Flag + imageStr;
    return imageArray;
  }
}

function arrayBufferToBase64(buffer: any) {
  var binary = "";
  var bytes = [].slice.call(new Uint8Array(buffer));

  bytes.forEach((b) => (binary += String.fromCharCode(b)));

  return window.btoa(binary);
}
